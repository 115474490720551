// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-toggle{
    width: 100% !important;
    text-align: start !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    background-color: #f5f8fa !important;
}
.dropdown-menu {
    width: 100%;
    left: 0;
    height: 20vh !important;
    overflow-y: auto !important;
  }`, "",{"version":3,"sources":["webpack://./src/app/modules/accounts/components/settings/cards/style.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,4BAA4B;IAC5B,wBAAwB;IACxB,8BAA8B;IAC9B,yCAAyC;IACzC,oCAAoC;AACxC;AACA;IACI,WAAW;IACX,OAAO;IACP,uBAAuB;IACvB,2BAA2B;EAC7B","sourcesContent":[".dropdown-toggle{\n    width: 100% !important;\n    text-align: start !important;\n    display: flex !important;\n    align-items: center !important;\n    justify-content: space-between !important;\n    background-color: #f5f8fa !important;\n}\n.dropdown-menu {\n    width: 100%;\n    left: 0;\n    height: 20vh !important;\n    overflow-y: auto !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
