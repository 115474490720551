const Location = {
  position: {
    lat: '',
    lng: '',
  },
  mapUrl: '',
  placeName: '',
  updateAndTrack: false,
}

export {Location}
