// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-switch-handle .ant-switch-handle {
    background-color: red;
  }
  `, "",{"version":3,"sources":["webpack://./src/app/modules/profile/components/TeamCollab/TeamTable.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;EACvB","sourcesContent":[".custom-switch-handle .ant-switch-handle {\n    background-color: red;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
