import React from "react";

const Website = ({ light, temp }) => {
  return (
    <div key={temp}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.330811"
          y="0.330811"
          width="39.3384"
          height="39.3384"
          rx="19.6692"
          stroke={`url(#paint0_linear_953_77122_${temp})`}
          stroke-width="0.661622"
        />
        <path
          d="M11.4018 17.532C11.2228 18.2289 11.1325 18.9455 11.133 19.665C11.133 20.4009 11.2269 21.1154 11.4018 21.798H14.4391C14.2954 20.3796 14.2954 18.9504 14.4391 17.532H11.4018ZM12.2742 15.399H14.7762C15.0599 14.1011 15.4694 12.9386 15.9717 11.9713C14.4233 12.7172 13.1351 13.9115 12.2742 15.399ZM27.9282 17.532H24.8908C25.0346 18.9504 25.0346 20.3796 24.8908 21.798H27.9282C28.2873 20.3987 28.2873 18.9313 27.9282 17.532ZM27.0558 15.399C26.1949 13.9115 24.9067 12.7172 23.3583 11.9713C23.8617 12.9386 24.2701 14.1011 24.5538 15.399H27.0558ZM16.5849 17.532C16.505 18.2402 16.4652 18.9523 16.4655 19.665C16.4655 20.3956 16.506 21.1101 16.5849 21.798H22.7451C22.9057 20.3805 22.9057 18.9495 22.7451 17.532H16.5849ZM16.9668 15.399H22.3632C22.1692 14.5973 21.8892 13.8189 21.5282 13.0772C20.8584 11.7388 20.1417 11.133 19.665 11.133C19.1872 11.133 18.4716 11.7388 17.8018 13.0772C17.4659 13.7513 17.1833 14.5351 16.9668 15.399ZM12.2742 23.931C13.1351 25.4185 14.4233 26.6128 15.9717 27.3587C15.4683 26.3914 15.0599 25.2289 14.7762 23.931H12.2742ZM27.0558 23.931H24.5538C24.2701 25.2289 23.8606 26.3914 23.3583 27.3587C24.9067 26.6128 26.1949 25.4185 27.0558 23.931ZM16.9668 23.931C17.1833 24.7949 17.4659 25.5787 17.8018 26.2528C18.4716 27.5912 19.1883 28.197 19.665 28.197C20.1428 28.197 20.8584 27.5912 21.5282 26.2528C21.8641 25.5787 22.1467 24.7949 22.3632 23.931H16.9668ZM19.665 30.33C13.7747 30.33 9 25.5553 9 19.665C9 13.7747 13.7747 9 19.665 9C25.5553 9 30.33 13.7747 30.33 19.665C30.33 25.5553 25.5553 30.33 19.665 30.33Z"
          fill={`url(#paint1_linear_953_77122_${temp})`}
        />
        <defs>
          <linearGradient
            id={`paint0_linear_953_77122_${temp}`}
            x1="20"
            y1="0"
            x2="20"
            y2="40"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color={light ? "#CBD5E1" : "#3A57EC"} />
            <stop offset="1" stop-color={light ? "#CBD5E1" : "#7F6AFF"} />
          </linearGradient>
          <linearGradient
            id={`paint1_linear_953_77122_${temp}`}
            x1="19.665"
            y1="9"
            x2="19.665"
            y2="30.33"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color={light ? "#CBD5E1" : "#3A57EC"} />
            <stop offset="1" stop-color={light ? "#CBD5E1" : "#7F6AFF"} />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Website;
