const Video = {
  videoTitle: '',
  description: '',
  videoUrl: '',
  updateAndTrack: false,
  preview: {
    backGroundColor: '#CFD5FF',
    textColor: '#303038',
    buttonColor: '#ffff',
  },
};

export { Video };
