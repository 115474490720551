// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-menu {
  width: 100%;
  left: 0;
  height: 20vh !important;
  overflow-y: auto !important;
}

/* Change the background color of the dropdown */
.dropdown-item {
  color: #000;
}

/* Change the background color of the dropdown when hovered */
.dropdown-item:hover {
  color: #ffffff;
  background-color: #1d59f9;
}
.dropdown {
  width: 100% !important;
}
.dropdown-toggle {
  border: 1px solid #1d59f9 !important;
}
.dropdown-toggle::after {
  color: #1d59f9 !important;
}

.dropdown-toggle:hover {
  border: 1px solid #1d59f9 !important;
  background-color: white !important;
}
.form-control {
  border: 1px solid #1d59f9 !important;
}
#country-dropdown {
  background-color: white !important;
  color: #000000 !important;
}
#change-btn {
  background-color: #1d59f9 !important ;
}
#change-btn:hover {
  background-color: #1d59f9 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/profile/components/QrCode/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,OAAO;EACP,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA,gDAAgD;AAChD;EACE,WAAW;AACb;;AAEA,6DAA6D;AAC7D;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,sBAAsB;AACxB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;EACpC,kCAAkC;AACpC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,kCAAkC;EAClC,yBAAyB;AAC3B;AACA;EACE,qCAAqC;AACvC;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".dropdown-menu {\n  width: 100%;\n  left: 0;\n  height: 20vh !important;\n  overflow-y: auto !important;\n}\n\n/* Change the background color of the dropdown */\n.dropdown-item {\n  color: #000;\n}\n\n/* Change the background color of the dropdown when hovered */\n.dropdown-item:hover {\n  color: #ffffff;\n  background-color: #1d59f9;\n}\n.dropdown {\n  width: 100% !important;\n}\n.dropdown-toggle {\n  border: 1px solid #1d59f9 !important;\n}\n.dropdown-toggle::after {\n  color: #1d59f9 !important;\n}\n\n.dropdown-toggle:hover {\n  border: 1px solid #1d59f9 !important;\n  background-color: white !important;\n}\n.form-control {\n  border: 1px solid #1d59f9 !important;\n}\n#country-dropdown {\n  background-color: white !important;\n  color: #000000 !important;\n}\n#change-btn {\n  background-color: #1d59f9 !important ;\n}\n#change-btn:hover {\n  background-color: #1d59f9 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
