import React from "react";

const Whatsapp = ({ light }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.330811"
        y="0.330811"
        width="39.3384"
        height="39.3384"
        rx="19.6692"
        stroke="url(#paint0_linear_953_77116)"
        stroke-width="0.661622"
      />
      <path
        d="M27.1304 12.1036C26.1527 11.1159 24.988 10.3328 23.7045 9.79999C22.4209 9.26715 21.0441 8.99522 19.6543 9.00006C13.8312 9.00006 9.08532 13.746 9.08532 19.569C9.08532 21.4354 9.57591 23.2485 10.4931 24.8482L9 30.33L14.5991 28.8582C16.1455 29.7008 17.8839 30.1487 19.6543 30.1487C25.4774 30.1487 30.2233 25.4028 30.2233 19.5797C30.2233 16.7535 29.1248 14.0979 27.1304 12.1036ZM19.6543 28.357C18.0759 28.357 16.5295 27.9304 15.175 27.1305L14.8551 26.9385L11.5276 27.8131L12.4128 24.5709L12.1995 24.2403C11.3226 22.84 10.8569 21.2213 10.8557 19.569C10.8557 14.7272 14.8017 10.7811 19.6436 10.7811C21.9899 10.7811 24.1976 11.6983 25.8507 13.362C26.6692 14.1768 27.3178 15.1459 27.759 16.2132C28.2002 17.2805 28.4252 18.4248 28.4209 19.5797C28.4422 24.4216 24.4962 28.357 19.6543 28.357ZM24.4749 21.7874C24.2082 21.6594 22.9071 21.0195 22.6725 20.9235C22.4272 20.8382 22.2566 20.7955 22.0753 21.0515C21.8939 21.3181 21.3927 21.9153 21.2434 22.086C21.0941 22.2673 20.9341 22.2886 20.6675 22.15C20.4009 22.022 19.5477 21.734 18.5451 20.8382C17.7559 20.1343 17.2334 19.2704 17.0734 19.0038C16.9241 18.7372 17.0521 18.5985 17.1907 18.4599C17.308 18.3426 17.4573 18.1506 17.5853 18.0013C17.7133 17.852 17.7666 17.7347 17.8519 17.564C17.9372 17.3827 17.8946 17.2334 17.8306 17.1054C17.7666 16.9775 17.2334 15.6763 17.0201 15.1431C16.8068 14.6312 16.5828 14.6952 16.4228 14.6845H15.9109C15.7296 14.6845 15.4523 14.7485 15.207 15.0151C14.9724 15.2817 14.2898 15.9216 14.2898 17.2228C14.2898 18.5239 15.239 19.7823 15.367 19.953C15.495 20.1343 17.2334 22.8005 19.8783 23.9417C20.5075 24.219 20.9981 24.3789 21.382 24.4963C22.0113 24.6989 22.5872 24.6669 23.0458 24.6029C23.5577 24.5283 24.6135 23.963 24.8268 23.3444C25.0508 22.7259 25.0508 22.2033 24.9761 22.086C24.9015 21.9687 24.7415 21.9153 24.4749 21.7874Z"
        fill="url(#paint1_linear_953_77116)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_953_77116"
          x1="20"
          y1="0"
          x2="20"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={light ? "#CBD5E1" : "#3A57EC"} />
          <stop offset="1" stop-color={light ? "#CBD5E1" : "#7F6AFF"} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_953_77116"
          x1="19.6116"
          y1="9"
          x2="19.6116"
          y2="30.33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={light ? "#CBD5E1" : "#3A57EC"} />
          <stop offset="1" stop-color={light ? "#CBD5E1" : "#7F6AFF"} />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Whatsapp;
