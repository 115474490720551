const BusinessCard = {
  firstName: '',
  lastName: '',
  email: '',
  workPhone: '',
  mobilePhone: '',
  companyName: '',
  jobTitle: '',
  street: '',
  city: '',
  zipcode: '',
  country: '',
  state: '',
  website: '',
  summary: '',
  updateAndTrack: false,
};
export { BusinessCard };
